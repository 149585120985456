import { delay, put, takeEvery } from 'redux-saga/effects';
import { clearNotification } from '.';
import { ActionTypes, SetNotificationRequestAction, setNotificationSuccess } from './actions';

/**
 * This file defines the redux sagas.
 *
 * Export any generator functions that you want to be included in the redux saga middleware.
 * You will need to add this to ~/client/store/saga/index.ts
 *
 * */

/** ID to keep track of current notifications. This is not globally unique, but it unique from within the store */
let notificationId: number = 1;

/** Listen for SET NOTIFICATION REQUEST messages */
export function* setNotificationSaga() {
    yield takeEvery(ActionTypes.SET_NOTIFICATION_REQUEST, setNotification);
}

/**
 * Create the notification and dispatch to the store
 * Set a timeout to remove the notification if the timeout property is set
 * @param action The SetNotificationRequestAction action
 */
function* setNotification(action: SetNotificationRequestAction) {
    const notification = { title: action.title, body: action.body, id: notificationId++ };

    yield put(setNotificationSuccess(notification));

    if (action.timeout) {
        yield delay(action.timeout * 1000);
        yield put(clearNotification(notification.id));
    }
}