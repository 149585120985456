import { Action, ActionCreator } from 'redux';
import { FeatureFlag } from '.';
import { ActionTypes, DeleteFeatureFlagAction, GetFeatureFlagRequestAction, PostPutFeatureFlagAction, GetFeatureFlagHistoryRequestAction }
    from './actions';

/**
 * Public Action Functions
 *
 * These functions are for creating actions to be dispatched from your main page compoents.
 * These methods will be available on the main module export.
 *
 * Example usage:
 *
 * import * as ApplicationSaga from '../store/sagas/application';
 *
 *  const dispatch = useDispatch();
 *  useEffect(() => { dispatch(ApplicationSaga.getApplications()) }, []);
 * */

export const getFeatureFlags: ActionCreator<GetFeatureFlagRequestAction> = (applicationId: number) => ({ type: ActionTypes.GET_FEATURE_FLAG_REQUEST, applicationId: applicationId });

export const getFeatureFlagHistory: ActionCreator<GetFeatureFlagHistoryRequestAction> = (applicationId: number, featureFlagId: number) => ({ type: ActionTypes.GET_FEATURE_FLAG_HISTORY_REQUEST, applicationId: applicationId, featureFlagId: featureFlagId });

export const createFeatureFlag: ActionCreator<PostPutFeatureFlagAction> = (featureFlag: FeatureFlag) => ({ type: ActionTypes.POST_FEATURE_FLAG_REQUEST, featureFlag });

export const updateFeatureFlag: ActionCreator<PostPutFeatureFlagAction> = (featureFlag: FeatureFlag) => ({ type: ActionTypes.PUT_FEATURE_FLAG_REQUEST, featureFlag });

export const deleteFeatureFlag: ActionCreator<DeleteFeatureFlagAction> = (applicationId: number, featureFlagId: number) => ({ type: ActionTypes.DELETE_FEATURE_FLAG_REQUEST, applicationId: applicationId, featureFlagId: featureFlagId });

export const clearState: ActionCreator<Action> = () => ({ type: ActionTypes.CLEAR_STATE });