import {  ActionCreator } from 'redux';
import { Application } from '.';
import { ActionTypes, DeleteApplicationAction, GetApplicationsRequestAction, PostPutApplicationAction } from './actions';

/**
 * Public Action Functions
 *
 * These functions are for creating actions to be dispatched from your main page compoents.
 * These methods will be available on the main module export.
 *
 * Example usage:
 *
 * import * as ApplicationSaga from '../store/sagas/application';
 *
 *  const dispatch = useDispatch();
 *  useEffect(() => { dispatch(ApplicationSaga.getApplications()) }, []);
 * */

export const getApplications: ActionCreator<GetApplicationsRequestAction> = (dontReload: boolean = false) => ({ type: ActionTypes.GET_APPLICATIONS_REQUEST, dontReload: dontReload });

export const createApplication: ActionCreator<PostPutApplicationAction> = (application: Application) => ({ type: ActionTypes.POST_APPLICATION_REQUEST, application });

export const updateApplication: ActionCreator<PostPutApplicationAction> = (application: Application) => ({ type: ActionTypes.PUT_APPLICATION_REQUEST, application });

export const deleteApplication: ActionCreator<DeleteApplicationAction> = (applicationId: number) => ({ type: ActionTypes.DELETE_APPLICATION_REQUEST, applicationId: applicationId });