import { Action, ActionCreator } from 'redux';
import { Notification } from './';
/**
 * This file defines actions that are internal to this saga. Main page components should not call or use these exports directly.
 * */

/**
 * This defines the values that are valid for the action.type property
 * */

const actionTypes = {
    SET_NOTIFICATION_REQUEST: '@@notification/SET_NOTIFICATION_REQUEST',
    SET_NOTIFICATION_SUCCESS: '@@notification/SET_NOTIFICATION_SUCCESS',
    CLEAR_NOTIFICATION: '@@notification/CLEAR_NOTIFICATION'
};
export const ActionTypes = actionTypes;

/**
 * Define all actions.
 * Actions are objects that are used by the reducer to modify state.
 * */

export interface SetNotificationRequestAction extends Action {
    title: string;
    body: string;
    timeout?: number;
}

export interface SetNotificationSuccessAction extends Action {
    notification: Notification;
    timeout?: number;
}

export interface ClearNotificationRequestAction extends Action {
    id?: number;
}

/**
 * For type safety in the reducer function, create a union with all action interfaces
 * */
export type NotificationActions = SetNotificationSuccessAction | ClearNotificationRequestAction;

/**
 * Define all internal action creators that are used only for dispatching actions from your saga functions
 *
 */

/**
 * Sets the notification
 * @param notification The notification to set
 */
export const setNotificationSuccess: ActionCreator<SetNotificationSuccessAction> = (notification: Notification) =>
({
    type: actionTypes.SET_NOTIFICATION_SUCCESS,
    notification: notification
});