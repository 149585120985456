import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import {
    ActionTypes,
    DeleteFeatureFlagAction,
    GetFeatureFlagRequestAction,
    getFeatureFlagsSuccess,
    getFeatureFlagHistorySuccess,
    loadingComplete,
    PostPutFeatureFlagAction,
    GetFeatureFlagHistoryRequestAction
} from './actions';
import * as Api from './api';
import * as NotificationSaga from '../notification';

/**
 * This file defines the redux sagas.
 *
 * Export any generator functions that you want to be included in the redux saga middleware.
 * You will need to add this to ~/client/store/saga/index.ts
 *
 * */

const errorTitle = 'FeatureFlag API Error';
const errorPrefix = 'Status: ';

export function* getFeatureFlagRequestSaga() {
    yield takeLatest(ActionTypes.GET_FEATURE_FLAG_REQUEST, getFeatureFlagsRequest);
};

function* getFeatureFlagsRequest(action: GetFeatureFlagRequestAction) {
    yield call(getFeatureFlags, action.applicationId);
}

function* getFeatureFlags(applicationId: number) {
    try {
        const featureFlags: any[] = yield call(Api.getFeatureFlags, applicationId);
        yield put(getFeatureFlagsSuccess(featureFlags));
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}

export function* createFeatureFlagHistoryRequestSaga() {
    yield takeEvery(ActionTypes.GET_FEATURE_FLAG_HISTORY_REQUEST, getFeatureFlagHistoryRequest);
}
function* getFeatureFlagHistoryRequest(action: GetFeatureFlagHistoryRequestAction) {
    yield call(getFeatureFlagHistory, action.applicationId, action.featureFlagId);
}
function* getFeatureFlagHistory(applicationId: number, featureFlagId: number) {
    try {
        const featureFlagHistory: any[] = yield call(Api.getFeatureFlagHistory, applicationId, featureFlagId);
        yield put(getFeatureFlagHistorySuccess(featureFlagHistory));
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}

export function* createFeatureFlagRequestSaga() {
    yield takeEvery(ActionTypes.POST_FEATURE_FLAG_REQUEST, createFeatureFlag);
}

function* createFeatureFlag(action: PostPutFeatureFlagAction) {
    try {
        yield call(Api.createFeatureFlag, action.featureFlag);
        yield call(getFeatureFlags, action.featureFlag.applicationId);
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}

export function* updateFeatureFlagRequestSaga() {
    yield takeEvery(ActionTypes.PUT_FEATURE_FLAG_REQUEST, updateFeatureFlag);
}

function* updateFeatureFlag(action: PostPutFeatureFlagAction) {
    try {
        yield call(Api.updateFeatureFlag, action.featureFlag);
        yield call(getFeatureFlags, action.featureFlag.applicationId);
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}

export function* deleteFeatureFlagSaga() {
    yield takeEvery(ActionTypes.DELETE_FEATURE_FLAG_REQUEST, deleteFeatureFlag);
}

function* deleteFeatureFlag(action: DeleteFeatureFlagAction) {
    try {
        yield call(Api.deleteFeatureFlag, action.applicationId, action.featureFlagId);
        yield call(getFeatureFlags, action.applicationId);
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}