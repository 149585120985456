import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';

export interface Claim {
    type: string;
    value: string;
}

export interface AuthenticationState {
    isAuthenticated?: boolean;
    claims?: Claim[];
}