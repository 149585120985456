import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routePaths } from '../../../routes';

const Home = () => (
    <>
        <h1>ProAg Admin Tool</h1>
        <Card className="mb-3">
            <Card.Header className="text-primary">Applications List</Card.Header>
            <Card.Body>Add, edit or delete applications.</Card.Body>
            <Card.Footer><Link to={routePaths.applicationList}>Go to Applications List</Link></Card.Footer>
        </Card>

        <Card className="mb-3">
            <Card.Header className="text-primary">Feature Flags</Card.Header>
            <Card.Body>Feature Flags is a centralized repository of configuration items that can be used to toggle software functionality without deploying code. This tool allows you to toggle, create, edit or delete features.</Card.Body>
            <Card.Footer><Link to={routePaths.featureFlagList}>Go to Feature Flags</Link></Card.Footer>
        </Card>
    </>
);

export default connect()(Home);