import { Routes, Route, Navigate } from 'react-router-dom';
import { routePaths } from './routes';
import { AuthenticateUser } from './components/authentication/AuthenticateUser';
import { ProtectedRoute } from './components/authentication/ProtectedRoute';

import Layout from './components/displayPartials/Layout';
import Home from './components/pages/home';
import ApplicationList from './components/pages/application/List';
import FeatureFlagList from './components/pages/featureFlag/List';

import './global.scss';

export default () => {

    return (
        <AuthenticateUser>
            <Layout>
                <Routes>
                    <Route path={routePaths.home} element={<Home/>} />
                    <Route path={routePaths.applicationList} element={<ProtectedRoute><ApplicationList/></ProtectedRoute>} />
                    <Route path={routePaths.featureFlagList} element={<ProtectedRoute><FeatureFlagList/></ProtectedRoute>} />
                    <Route path="*" element={<Navigate to={routePaths.home} />}></Route>
                </Routes>
            </Layout>
        </AuthenticateUser>
    );
};