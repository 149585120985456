import * as AuthenticationSaga from './';
import { ActionTypes, AuthenticationActions, AuthenticationSuccessAction } from './actions';

const initialState: AuthenticationSaga.AuthenticationState = { isAuthenticated: undefined, claims: [] };

const reducer = (state: AuthenticationSaga.AuthenticationState = initialState, action: AuthenticationActions) => {
    switch (action.type) {
        case ActionTypes.AUTHENTICATION_SUCCESS:
            const successAction = action as AuthenticationSuccessAction;
            return { isAuthenticated: successAction.isAuthenticated, claims: successAction.claims }

        case ActionTypes.AUTHENTICATION_FAILED:
            return { isAuthenticated: false, claims: [] };
    }

    return state;
};

export default reducer;