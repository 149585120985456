import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { login, logout } from '../../store/sagas/authentication/api';
import { routePaths } from '../../routes';
import styles from './Header.module.scss';
import { GlobalState } from '../../store';
import * as AuthenticationSaga from '../../store/sagas/authentication';

const Header = () => {
    const authentication = useSelector<GlobalState, AuthenticationSaga.AuthenticationState>(state => state.authenticationState);

    return (
        <header className="d-flex">
            <Navbar className={`${styles.navbar} flex-grow-1 d-flex`} role="navigation">
                <Navbar.Brand>
                    <Link to={routePaths.home} className="ms-3">
                        <img src="/proag_logo.png" />
                    </Link>
                </Navbar.Brand>
                <span className={`${styles.title} flex-grow-1`}>
                    ProAg Admin Tool
                </span>
                <div className="text-white pe-3">{authentication.claims?.find(c => c.type == 'name')?.value ?? ''}</div>
            </Navbar>
            {
                authentication.isAuthenticated ?
                    <Link to="#" className={`${styles.link} text-end p-3 text-white fs-6 fw-bold text-uppercase`} onClick={logout}>Logout</Link>
                    :
                    <Link to="#" className={`${styles.link} text-end p-3 text-white fs-6 fw-bold text-uppercase`} onClick={login}>Login</Link>
            }
        </header>
    );
}

export default Header;