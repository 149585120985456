import { Application } from './';
import ApiConfig, { throwErrors } from '../../../apiConfig';

/**
 * This file contains functions that make the HTTP requests to the api
 * These methods will be called from your sagas
 * */

export const getApplications = () =>
        fetch(`${ApiConfig.apiUrl}/Applications`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(throwErrors)
            .then(response => response.json() as Promise<Application[]>);

export const createApplication = (application: Application) =>
    fetch(`${ApiConfig.apiUrl}/Applications`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(application)
    }).then(throwErrors);

export const updateApplication = (application: Application) =>
    fetch(`${ApiConfig.apiUrl}/Applications/${application.id}`, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: application.name, description: application.description })
    }).then(throwErrors);

export const deleteApplication = (applicationId: number) =>
    fetch(`${ApiConfig.apiUrl}/Applications/${applicationId}`, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(throwErrors);