import { takeLatest, takeEvery, put, call, select } from 'redux-saga/effects';
import { ApplicationState } from '.';
import { GlobalState } from '../..';
import { ActionTypes, DeleteApplicationAction, GetApplicationsRequestAction, getApplicationsSuccess, loadingComplete, PostPutApplicationAction } from './actions';
import * as Api from './api';
import * as NotificationSaga from '../notification';

/**
 * This file defines the redux sagas.
 *
 * Export any generator functions that you want to be included in the redux saga middleware.
 * You will need to add this to ~/client/store/saga/index.ts
 *
 * */

const errorTitle = 'Application API Error';
const errorPrefix = 'Status: ';

export function* getApplicationRequestSaga() {
    yield takeLatest(ActionTypes.GET_APPLICATIONS_REQUEST, getApplicationRequest);
};

function* getApplicationRequest(action: GetApplicationsRequestAction) {
    yield call(getApplications, action.dontReload);
}

function* getApplications(dontReload: boolean = false) {
    if (dontReload) {
        const applicationState: ApplicationState = yield select((state: GlobalState) => state.applicationState);
        if (applicationState.applications.length > 0) { return; }
    }

    try {
        const applications: any[] = yield call(Api.getApplications);
        yield put(getApplicationsSuccess(applications));
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}

export function* createApplicationRequestSaga() {
    yield takeEvery(ActionTypes.POST_APPLICATION_REQUEST, createApplication);
}

function* createApplication(action: PostPutApplicationAction) {
    try {
        yield call(Api.createApplication, action.application);
        yield call(getApplications);
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}

export function* updateApplicationRequestSaga() {
    yield takeEvery(ActionTypes.PUT_APPLICATION_REQUEST, updateApplication);
}

function* updateApplication(action: PostPutApplicationAction) {
    try {
        yield call(Api.updateApplication, action.application);
        yield call(getApplications);
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}

export function* deleteApplicationSaga() {
    yield takeEvery(ActionTypes.DELETE_APPLICATION_REQUEST, deleteApplication);
}

function* deleteApplication(action: DeleteApplicationAction) {
    try {
        yield call(Api.deleteApplication, action.applicationId);
        yield call(getApplications);
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification(errorTitle, `${errorPrefix} ${response.status} ${response.statusText}`));
        yield put(loadingComplete());
    }
}