import * as React from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as FeatureFlagSaga from '../../../store/sagas/featureFlag';

interface FeatureFlagParams {
    applicationId?: number;
    featureFlag?: FeatureFlagSaga.FeatureFlag;
    handleClose: Function;
}

const FeatureFlagEdit: React.FC<FeatureFlagParams> = ({ applicationId, featureFlag, handleClose }) => {
    const dispatch = useDispatch();

    const isEdit = featureFlag != undefined;
    const formValues = {
        applicationId: isEdit ? featureFlag?.applicationId ?? 0 : applicationId ?? 0,
        applicationName: featureFlag?.applicationName ?? '',
        featureFlagId: featureFlag?.featureFlagId ?? 0,
        name: featureFlag?.name ?? '',
        description: featureFlag?.description ?? '',
        enabled: featureFlag?.enabled ?? true,
        featureFlagHistory: featureFlag?.featureFlagHistory ?? []
    };

    const validationSchema = yup.object({
        name: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
    });

    const formik = useFormik<FeatureFlagSaga.FeatureFlag>({
        initialValues: formValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (isEdit) {
                dispatch(FeatureFlagSaga.updateFeatureFlag(values));
            }
            else {
                dispatch(FeatureFlagSaga.createFeatureFlag(values));
            }
            handleClose();
        },
        enableReinitialize: true
    });

    return (
        <>
            <h1>{isEdit ? 'Edit' : 'Create'} Feature Flag</h1>

            <Form onSubmit={formik.handleSubmit}>
                <FloatingLabel controlId="applicationId" label="Application Id" className="mb-3">
                    <Form.Control type="text" placeholder="Enter the Application Id"
                        readOnly value={formik.values.applicationId} />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.applicationId}
                    </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel controlId="featureId" label="Feature Id" className="mb-3" hidden={!isEdit}>
                    <Form.Control readOnly value={formik.values.featureFlagId} />
                </FloatingLabel>

                <FloatingLabel controlId="name" label="Feature Flag Name" className="mb-3">
                    <Form.Control type="text" placeholder="Enter the feature flag name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isValid={formik.touched.name && !formik.errors.name}
                        isInvalid={formik.touched.name && !!formik.errors.name}
                        value={formik.values.name} />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.name}
                    </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel controlId="description" label="Feature Flag Description" className="mb-3">
                    <Form.Control as="textarea" placeholder="Enter the feature flag description"
                        style={{ height: '6rem' }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isValid={formik.touched.description && !formik.errors.description}
                        isInvalid={formik.touched.description && !!formik.errors.description}
                        value={formik.values.description} />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.description}
                    </Form.Control.Feedback>
                </FloatingLabel>

                <Form.Group controlId="enabled">
                    <Form.Label>Enabled?</Form.Label>
                    <Form.Check inline type="checkbox" className="ms-3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.enabled} />
                </Form.Group>

                <div className="float-end">
                    <Button variant="outline-accent10" className="me-3" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button variant="accent1" type="submit">
                        Submit
                    </Button>
                </div>

            </Form>
        </>);
}

export default FeatureFlagEdit;