import { ActionCreator } from 'redux';
import { ActionTypes, ClearNotificationRequestAction, SetNotificationRequestAction } from './actions';

/**
 * Public Action Functions
 *
 * These functions are for creating actions to be dispatched from your main page compoents.
 * These methods will be available on the main module export.
 *
 * Example usage:
 *
 * import * as ApplicationSaga from '../store/sagas/application';
 *
 *  const dispatch = useDispatch();
 *  useEffect(() => { dispatch(ApplicationSaga.getApplications()) }, []);
 * */

/**
 *
 * @param notification The notification to set
 * @param timeout? Notification is removed from state automatically after [timeout] seconds
 */
export const setNotification: ActionCreator<SetNotificationRequestAction> = (title: string, body: string, timeout?: number) => ({ type: ActionTypes.SET_NOTIFICATION_REQUEST, title: title, body: body, timeout: timeout });

export const clearNotification: ActionCreator<ClearNotificationRequestAction> = (id?: number) => ({ type: ActionTypes.CLEAR_NOTIFICATION, id: id });