import { FeatureFlag, FeatureFlagHistory } from './';
import ApiConfig, { throwErrors } from '../../../apiConfig';

/**
 * This file contains functions that make the HTTP requests to the api
 * These methods will be called from your sagas
 * */

export const getFeatureFlags = (applicationId: number) =>
    fetch(`${ApiConfig.apiUrl}/Applications/${applicationId}/FeatureFlags`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(throwErrors)
        .then(response => response.json() as Promise<FeatureFlag[]>);

export const getFeatureFlagHistory = (applicationId: number, featureFlagId: number) =>
    fetch(`${ApiConfig.apiUrl}/Applications/${applicationId}/FeatureFlags/${featureFlagId}/history`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(throwErrors)
        .then(response => response.json() as Promise<FeatureFlagHistory>);

export const createFeatureFlag = (featureFlag: FeatureFlag) =>
    fetch(`${ApiConfig.apiUrl}/Applications/${featureFlag.applicationId}/FeatureFlags`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(featureFlag)
    }).then(throwErrors);

export const updateFeatureFlag = (featureFlag: FeatureFlag) =>
    fetch(`${ApiConfig.apiUrl}/Applications/${featureFlag.applicationId}/FeatureFlags/${featureFlag.featureFlagId}`, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: featureFlag.name, description: featureFlag.description, enabled: featureFlag.enabled })
    }).then(throwErrors);

export const deleteFeatureFlag = (applicationId: number, featureFlagId: number) =>
    fetch(`${ApiConfig.apiUrl}/Applications/${applicationId}/FeatureFlags/${featureFlagId}`, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(throwErrors);