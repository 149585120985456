import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { GlobalState } from './';
import { Reducers, RootSaga } from './sagas';

export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();

    const middleware = [
        sagaMiddleware
    ];

    const rootReducer = combineReducers<GlobalState>({
        ...Reducers
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return {
        ...createStore(
            rootReducer,
            compose(applyMiddleware(...middleware), ...enhancers)
        ),
        runSaga: () => sagaMiddleware.run(RootSaga)
    };
}