import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "@proag/sprout/themes/ag-theme-sprout.css";
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import * as FeatureFlagSaga from '../../../store/sagas/featureFlag';
import caseInsensitiveComparator from "../../helpers/caseInsensitiveComparator";
import dateComparator from "../../helpers/dateComparator";

interface FeatureFlagHistoryParams {
    featureFlag?: FeatureFlagSaga.FeatureFlag;
    handleClose: Function;
}

const FeatureFlagHistoryView: React.FC<FeatureFlagHistoryParams> = ({ featureFlag, handleClose }) => {

    const getDisplayableDateValue = (value?: Date) => {
        if (value) {
            return new Date(value).toLocaleString("en-US");
        }

        return '';
    }

    return (
        <>
            <h1>Feature Flag History</h1>

            <Form>
                <div
                    className="ag-theme-sprout"
                    style={{ height: 300 }}
                >
                <AgGridReact
                    alwaysShowHorizontalScroll={false}
                    alwaysShowVerticalScroll={true}
                    animateRows={true}
                    columnDefs={[
                        { field: "name", headerName: "Name", sortable: true, comparator: caseInsensitiveComparator, filter: true, wrapText: true, maxWidth: 180 },
                        { field: "description", headerName: "Description", wrapText: true, maxWidth: 160 },
                        {
                            headerName: "Enabled",
                            cellRenderer: (params: ICellRendererParams) => {
                                return <i className={`bi bi-${params.data.enabled ? 'check2-square' : 'x-square'} text-${params.data.enabled ? 'accent3' : 'accent2'}`} />
                            },
                            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", textAlign: 'center', },
                            maxWidth: 90
                        },
                        {
                            headerName: "Modified On",
                            sortable: true,
                            comparator: dateComparator,
                            cellRenderer: (params: ICellRendererParams) => {
                                return getDisplayableDateValue(params.data.modifiedOn);
                            },
                            wrapText: true, maxWidth: 120
                        },
                        { field: "modifiedBy", headerName: "Modified By", sortable: true, comparator: caseInsensitiveComparator, filter: true, wrapText: true, maxWidth: 180 }                        
                    ]}
                    defaultColDef={{
                        autoHeight: true,
                        cellStyle: () => ({
                            display: "flex",
                            alignItems: "center",
                        }),
                    }}
                    onGridReady={(params: GridReadyEvent) => params.api.sizeColumnsToFit()}
                    rowData={featureFlag?.featureFlagHistory}
                    rowHeight={40}
                    suppressCellFocus={true}
                />            </div>

                <div className="float-end">
                    <Button variant="outline-accent10" className="me-3" onClick={() => handleClose()}>
                        Close
                    </Button>
                </div>
            </Form>
        </>);
}

export default FeatureFlagHistoryView;