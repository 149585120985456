import { Reducer } from 'redux';
import * as NotificationSaga from './';
import { ActionTypes, ClearNotificationRequestAction, NotificationActions, SetNotificationSuccessAction } from './actions';

/**
 * Define your reducer function.
 * This function accepts a current state and an action and should return the modified state.
 * This is a pure function and no logic or async code belongs here.
 *
 * This function should be added to the Reducers object in ~/client/store/sagas/index.ts
 * */

const initialState: NotificationSaga.NotificationState = { notifications: [] };

const reducer: Reducer<NotificationSaga.NotificationState, NotificationActions> =
    (state: NotificationSaga.NotificationState = initialState, action: NotificationActions): NotificationSaga.NotificationState => {
        switch (action.type) {
            case ActionTypes.SET_NOTIFICATION_SUCCESS:
                const setNotificationRequestAction = action as SetNotificationSuccessAction
                return { notifications: [...state.notifications, setNotificationRequestAction.notification] };

            case ActionTypes.CLEAR_NOTIFICATION:
                const clearNotificationAction = action as ClearNotificationRequestAction;
                if (!clearNotificationAction.id) {
                    return initialState;
                }
                else {
                    return { ...state, notifications: state.notifications.filter(n => n.id != clearNotificationAction.id) };
                }
        }

        return state;
    };

export default reducer;