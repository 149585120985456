import { Reducer } from 'redux';
import * as ApplicationSaga from './';
import { ActionTypes, ApplicationActions, GetApplicationsResponseAction } from './actions';

/**
 * Define your reducer function.
 * This function accepts a current state and an action and should return the modified state.
 * This is a pure function and no logic or async code belongs here.
 *
 * This function should be added to the Reducers object in ~/client/store/sagas/index.ts
 * */

const initialState: ApplicationSaga.ApplicationState = { applications: [], loading: false };

const reducer: Reducer<ApplicationSaga.ApplicationState, ApplicationActions> =
    (state: ApplicationSaga.ApplicationState = initialState, action: ApplicationActions): ApplicationSaga.ApplicationState => {
        switch (action.type) {
            case ActionTypes.GET_APPLICATIONS_REQUEST:
                return { ...state, loading: true };

            case ActionTypes.GET_APPLICATIONS_SUCCESS:
                return { applications: (action as GetApplicationsResponseAction).applications, loading: false };

            case ActionTypes.POST_APPLICATION_REQUEST:
            case ActionTypes.PUT_APPLICATION_REQUEST:
            case ActionTypes.DELETE_APPLICATION_REQUEST:
                return { ...state, loading: true };

            case ActionTypes.POST_APPLICATION_SUCCESS:
            case ActionTypes.PUT_APPLICATION_SUCCESS:
            case ActionTypes.DELETE_APPLICATION_SUCCESS:
            case ActionTypes.LOADING_COMPLETE:
                return { ...state, loading: false };
        }

        return state;
    };

export default reducer;