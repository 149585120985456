import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../store";
import * as ApplicationSaga from '../../../store/sagas/application';
import * as FeatureFlagSaga from '../../../store/sagas/featureFlag';
import FeatureFlagEdit from './Edit';
import FeatureFlagHistoryView from './History';
import "ag-grid-community/styles/ag-grid.css";
import "@proag/sprout/themes/ag-theme-sprout.css";
import caseInsensitiveComparator from "../../helpers/caseInsensitiveComparator";
import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

interface ModalState {
    show: boolean;
    applicationId?: number;
    featureFlag?: FeatureFlagSaga.FeatureFlag;
}

interface ModalHistory {
    show: boolean;
    featureFlag?: FeatureFlagSaga.FeatureFlag;
}

const FeatureFlagList = () => {
    const dispatch = useDispatch();
    const [modalState, setModalState] = useState<ModalState>({ show: false });
    const [modalHistory, setModalHistory] = useState<ModalState>({ show: false });

    const applicationState = useSelector<GlobalState, ApplicationSaga.ApplicationState>((state) => state.applicationState);
    const featureFlagState = useSelector<GlobalState, FeatureFlagSaga.FeatureFlagState>((state) => state.featureFlagState);

    useEffect(() => { dispatch(ApplicationSaga.getApplications({ dontReload: true })) }, []);

    const applicationIdFormik = useFormik({
        initialValues: { applicationId: 0 },
        onSubmit: (values) => {
            if (values.applicationId > 0) {
                dispatch(FeatureFlagSaga.getFeatureFlags(values.applicationId));
            }
            else {
                dispatch(FeatureFlagSaga.clearState());
            }
        }
    });

    const handleModalClose = () => {
        setModalState({ ...modalState, show: false });
    }

    const handleModalHistoryClose = () => {
        setModalHistory({ ...modalState, show: false });
    }

    const toggleFeatureFlag = (featureFlag: FeatureFlagSaga.FeatureFlag) => {
        const featureFlagCopy = { ...featureFlag };
        featureFlagCopy.enabled = !featureFlagCopy.enabled;
        dispatch(FeatureFlagSaga.updateFeatureFlag(featureFlagCopy));
    }

    const deleteFeatureFlag = (featureFlag: FeatureFlagSaga.FeatureFlag) => {
        dispatch(FeatureFlagSaga.deleteFeatureFlag(featureFlag.applicationId, featureFlag.featureFlagId));
    }

    const viewFeatureFlagHistory = (featureFlag: FeatureFlagSaga.FeatureFlag) => {
        // retrieve history and then display history modal window
        dispatch(FeatureFlagSaga.getFeatureFlagHistory(featureFlag.applicationId, featureFlag.featureFlagId ?? 0));
        setModalHistory({ show: true, featureFlag: featureFlag});
    }

    const renderCreateFeatureFlagToolTip =
        (applicationIdFormik.values.applicationId == 0) ?
            <Tooltip id="createFeatureFlagTooltip">
                Select an application from the drop down first
            </Tooltip>
            : <></>;

    return (
        <>
            <OverlayTrigger placement="bottom" overlay={renderCreateFeatureFlagToolTip}>
                <div className="float-end mt-3">
                    <Button variant="accent1" disabled={applicationIdFormik.values.applicationId == 0}
                        onClick={() => setModalState({ show: true, applicationId: applicationIdFormik.values.applicationId })}>
                        Create Feature Flag
                    </Button>
                </div>
            </OverlayTrigger>
            <h1>Feature Flags</h1>
            <Form.Group as={Row}>
                <Form.Label column xs="auto">Application:</Form.Label>
                <Col xs="auto">
                    <Form.Select id="applicationId"
                        onChange={e => {
                            applicationIdFormik.handleChange(e);
                            applicationIdFormik.submitForm();
                        }}>
                        <option value={0}>Please choose an application</option>
                        {applicationState.applications.map(application => (
                            <option value={application.id} key={application.id}>{application.name}</option>))}
                    </Form.Select>
                </Col>
            </Form.Group>

            <div
                className="ag-theme-sprout"
                style={{ height:600 }}
            >
                <AgGridReact
                    
                    alwaysShowHorizontalScroll={false}
                    alwaysShowVerticalScroll={true}
                    animateRows={true}                
                    columnDefs={[
                        { field: "applicationId", headerName: "App Id", maxWidth: 80 },
                        { field: "featureFlagId", headerName: "Feature Id", maxWidth: 100 },
                        {
                            field: "name", headerName: "Name", sortable: true, comparator: caseInsensitiveComparator, filter: true, wrapText: true,
                            minWidth: 180
                        },
                        {
                            field: "description", headerName: "Description", wrapText: true,
                            minWidth: 200
                        },
                        {
                            headerName: "Enabled",
                            cellRenderer: (params: ICellRendererParams) => {
                                return <i className={`bi bi-${params.data.enabled ? 'check2-square' : 'x-square'} text-${params.data.enabled ? 'accent3' : 'accent2'}`} />
                            },
                            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", textAlign: 'center', },
                            maxWidth: 90
                        },
                        {
                            headerName: "Actions",
                            minWidth: 280,
                            cellRenderer: (params: ICellRendererParams) => {
                                return <>
                                    <Button size="sm" className="me-2" onClick={() => toggleFeatureFlag(params.data)}>
                                        {params.data.enabled ? 'Disable' : 'Enable'}
                                    </Button>

                                    <Button size="sm" className="me-2" onClick={() => setModalState({ show: true, featureFlag: params.data })}>
                                        Edit
                                    </Button>

                                    <Button size="sm" className="me-2" onClick={() => confirm('Deleting this Feature Flag may stop apps who are dependent upon this value to stop working. Continue?') && deleteFeatureFlag(params.data)}>
                                        Delete
                                    </Button>

                                    <Button size="sm" className="me-2" onClick={() => viewFeatureFlagHistory(params.data)} >
                                        History
                                    </Button>
                                </>                              
                            },
                        }
                    ]}
                    defaultColDef={{
                        autoHeight: true,
                        cellStyle: () => ({
                            display: "flex",
                            alignItems: "center",
                        }),                              
                    }}               
                    onGridReady={(params: GridReadyEvent) => params.api.sizeColumnsToFit()}                 
                    rowData={featureFlagState.featureFlags}
                    rowHeight={40}                                  
                    suppressCellFocus={true}
                />
            </div>

            <Modal show={modalState.show} backdrop="static">
                <Modal.Body>
                    <FeatureFlagEdit applicationId={modalState.applicationId} featureFlag={modalState.featureFlag} handleClose={handleModalClose} />
                </Modal.Body>
            </Modal>

            <Modal show={modalHistory.show} backdrop="static" size="lg">
                <Modal.Body>
                    <FeatureFlagHistoryView featureFlag={modalHistory.featureFlag} handleClose={handleModalHistoryClose} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FeatureFlagList;