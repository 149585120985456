import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';

/**
 * Declare all interfaces this saga uses
 * */

export interface Application {
    id?: number;
    name: string;
    description: string;
};

/**
 * Declare a main interface for this sagas state.
 * This should also be added to GlobalState in the ~/client/store/index.ts file
 * */

export interface ApplicationState {
    applications: Application[];
    loading: boolean;
};