import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router';
import { GlobalState } from '../../store';
import { login } from '../../store/sagas/authentication/api';
import * as AuthenticationSaga from '../../store/sagas/authentication';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }: any) => {
    const authentication = useSelector<GlobalState, AuthenticationSaga.AuthenticationState>(state => state.authenticationState);
    if (authentication.isAuthenticated) {
        return children;
    }
    else {
        login();
        return null;
    }
};