import { Action, ActionCreator } from 'redux';
import { FeatureFlag, FeatureFlagHistory } from './';

/**
 * This file defines actions that are internal to this saga. Main page components should not call or use these exports directly.
 * */

/**
 * This defines the values that are valid for the action.type property
 * */

const actionTypes = {
    GET_FEATURE_FLAG_REQUEST: '@@featureFlag/GET_APPLICATIONS_REQUEST',
    GET_FEATURE_FLAG_SUCCESS: '@@featureFlag/GET_APPLICATIONS_SUCCESS',
    GET_FEATURE_FLAG_HISTORY_REQUEST: '@@featureFlag/GET_APPLICATIONS_HISTORY_REQUEST',
    GET_FEATURE_FLAG_HISTORY_SUCCESS: '@@featureFlag/GET_APPLICATIONS_HISTORY_SUCCESS',
    POST_FEATURE_FLAG_REQUEST: '@@featureFlag/POST_APPLICATION_REQUEST',
    POST_FEATURE_FLAG_SUCCESS: '@@featureFlag/POST_APPLICATION_SUCCESS',
    PUT_FEATURE_FLAG_REQUEST: '@@featureFlag/PUT_APPLICATION_REQUEST',
    PUT_FEATURE_FLAG_SUCCESS: '@@featureFlag/PUT_APPLICATION_SUCCESS',
    DELETE_FEATURE_FLAG_REQUEST: '@@featureFlag/DELETE_APPLICATION_REQUEST',
    DELETE_FEATURE_FLAG_SUCCESS: '@@featureFlag/DELETE_APPLICATION_SUCCESS',
    CLEAR_STATE: '@@featureFlag/CLEAR_STATE',
    LOADING_COMPLETE: '@@featureFlag/LOADING_COMPLETE'
};
export const ActionTypes = actionTypes;

/**
 * Define all actions.
 * Actions are objects that are used by the reducer to modify state.
 * */

export interface GetFeatureFlagRequestAction extends Action {
    type: string;
    applicationId: number;
};

export interface GetFeatureFlagHistoryRequestAction extends Action {
    type: string;
    applicationId: number;
    featureFlagId: number;
};

export interface GetFeatureFlagResponseAction extends Action {
    type: string;
    featureFlags: FeatureFlag[];
};

export interface GetFeatureFlagHistoryResponseAction extends Action {
    type: string;
    featureFlagHistory: FeatureFlagHistory[];
};

export interface PostPutFeatureFlagAction extends Action {
    type: string;
    featureFlag: FeatureFlag
};

export interface DeleteFeatureFlagAction extends Action {
    type: string;
    applicationId: number;
    featureFlagId: number;
}

/**
 * For type safety in the reducer function, create a union with all action interfaces
 * */
export type FeatureFlagActions =
    GetFeatureFlagRequestAction |
    GetFeatureFlagResponseAction |
    GetFeatureFlagHistoryRequestAction |
    GetFeatureFlagHistoryResponseAction |
    PostPutFeatureFlagAction |
    DeleteFeatureFlagAction;

/**
 * Define all internal action creators that are used only for dispatching actions from your saga functions
 *
 */
export const getFeatureFlagsSuccess: ActionCreator<GetFeatureFlagResponseAction> = (featureFlags: FeatureFlag[]) =>
({
    type: actionTypes.GET_FEATURE_FLAG_SUCCESS,
    featureFlags: featureFlags
});

export const getFeatureFlagHistorySuccess: ActionCreator<GetFeatureFlagHistoryResponseAction> = (featureFlagHistory: FeatureFlagHistory[]) =>
({
    type: actionTypes.GET_FEATURE_FLAG_HISTORY_SUCCESS,
    featureFlagHistory: featureFlagHistory
});

export const loadingComplete: ActionCreator<Action> = () => ({ type: actionTypes.LOADING_COMPLETE });