import { Action, ActionCreator } from 'redux';
import { Application } from './';

/**
 * This file defines actions that are internal to this saga. Main page components should not call or use these exports directly.
 * */

/**
 * This defines the values that are valid for the action.type property
 * */

const actionTypes = {
    GET_APPLICATIONS_REQUEST: '@@application/GET_APPLICATIONS_REQUEST',
    GET_APPLICATIONS_SUCCESS: '@@application/GET_APPLICATIONS_SUCCESS',
    POST_APPLICATION_REQUEST: '@@application/POST_APPLICATION_REQUEST',
    POST_APPLICATION_SUCCESS: '@@application/POST_APPLICATION_SUCCESS',
    PUT_APPLICATION_REQUEST: '@@application/PUT_APPLICATION_REQUEST',
    PUT_APPLICATION_SUCCESS: '@@application/PUT_APPLICATION_SUCCESS',
    DELETE_APPLICATION_REQUEST: '@@application/DELETE_APPLICATION_REQUEST',
    DELETE_APPLICATION_SUCCESS: '@@application/DELETE_APPLICATION_SUCCESS',
    LOADING_COMPLETE: '@@application/LOADING_COMPLETE'
};
export const ActionTypes = actionTypes;

/**
 * Define all actions.
 * Actions are objects that are used by the reducer to modify state.
 * */

export interface GetApplicationsRequestAction extends Action {
    type: string;
    dontReload: boolean; // This flag will prevent calling the Api if we already have a valid list of applications in the store
};

export interface GetApplicationsResponseAction extends Action {
    type: string;
    applications: Application[];
};

export interface PostPutApplicationAction extends Action {
    type: string;
    application: Application
};

export interface DeleteApplicationAction extends Action {
    type: string;
    applicationId: number;
}

/**
 * For type safety in the reducer function, create a union with all action interfaces
 * */
export type ApplicationActions = GetApplicationsRequestAction | GetApplicationsResponseAction | PostPutApplicationAction | DeleteApplicationAction;

/**
 * Define all internal action creators that are used only for dispatching actions from your saga functions
 *
 */
export const getApplicationsSuccess: ActionCreator<GetApplicationsResponseAction> = (applications: Application[]) =>
({
    type: actionTypes.GET_APPLICATIONS_SUCCESS,
    applications: applications
});

export const loadingComplete: ActionCreator<Action> = () => ({ type: actionTypes.LOADING_COMPLETE });