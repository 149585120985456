import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as ApplicationSaga from '../../../store/sagas/application';

interface Application {
    id?: number;
    name: string;
    description: string;
};

interface ApplicationEditParams {
    application?: Application;
    handleClose: Function;
}

const ApplicationEdit: React.FC<ApplicationEditParams> = ({ application, handleClose }) => {
    const dispatch = useDispatch();

    const isEdit = application != undefined;
    const formValues = {
        id: application?.id ?? 0,
        name: application?.name ?? '',
        description: application?.description ?? ''
    };
    const validationSchema = yup.object({
        name: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: formValues,
        validationSchema: validationSchema,
        onSubmit: (application: ApplicationSaga.Application) => {
            if (isEdit) {
                dispatch(ApplicationSaga.updateApplication(application));
            }
            else {
                dispatch(ApplicationSaga.createApplication(application));
            }

            handleClose();
        },
        enableReinitialize: true
    });

    return (
        <>
            <h1>{isEdit ? 'Edit' : 'Create'} Application</h1>
            <Form onSubmit={formik.handleSubmit} className="mb-3">
                <FloatingLabel controlId="applicationId" label="Applicaiton Id" className="mb-3" hidden={!isEdit}>
                    <Form.Control readOnly value={formik.values.id} />
                </FloatingLabel>

                <FloatingLabel controlId="name" label="Application Name" className="mb-3">
                    <Form.Control type="text" placeholder="Enter the application name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isValid={formik.touched.name && !formik.errors.name}
                        isInvalid={formik.touched.name && !!formik.errors.name}
                        value={formik.values.name} />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.name}
                    </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel controlId="description" label="Application Description" className="mb-3">
                    <Form.Control as="textarea" placeholder="Enter the application description"
                        style={{ height: '6rem' }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isValid={formik.touched.description && !formik.errors.description}
                        isInvalid={formik.touched.description && !!formik.errors.description}
                        value={formik.values.description} />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.description}
                    </Form.Control.Feedback>
                </FloatingLabel>

                <div className="float-end">
                    <Button variant="outline-accent10" className="me-3" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button variant="accent1" type="submit">
                        Submit
                    </Button>
                </div>
            </Form>

        </>
    );
};

export default ApplicationEdit;