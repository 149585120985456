const caseInsensitiveComparator = (valueA: string, valueB: string): number => {
    const valueAInsensitive = valueA.toLowerCase();
    const valueBInsensitive = valueB.toLowerCase();
    if (valueAInsensitive < valueBInsensitive) {
      return -1;
    }
    if (valueAInsensitive > valueBInsensitive) {
      return 1;
    }
    return 0;
}

export default caseInsensitiveComparator;
