import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routePaths } from '../../routes';
import styles from './Menu.module.scss';

const Menu = () => (
    <div className={styles.leftNav}>
        <Nav className={`${styles.verticalNavigation} flex-column`}>
            <Link to={routePaths.applicationList}> <i className="bi bi-card-text" /> Applications</Link>
            <Link to={routePaths.featureFlagList}> <i className="bi bi-flag-fill" /> Feature Flags</Link>
        </Nav>
    </div>
);

export default Menu;