import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';

/**
 * Declare all interfaces this saga uses
 * */

export interface Notification {
    id: number;
    title: string;
    body: string;
};

/**
 * Declare a main interface for this sagas state.
 * This should also be added to GlobalState in the ~/client/store/index.ts file
 * */

export interface NotificationState {
    notifications: Notification[];
};