import { put, takeEvery, call } from 'redux-saga/effects';
import { AuthenticationState } from '.';
import { ActionTypes, authenticationFailed, authenticationSuccess } from './actions';
import { getUser } from './api';
import * as NotificationSaga from '../notification';

export function* authenticationRequestSaga() {
    yield takeEvery(ActionTypes.AUTHENTICATION_REQUEST, authenticationRequest);
}
function* authenticationRequest() {
    try {
        const user: AuthenticationState = yield call(getUser);
        yield put(authenticationSuccess(user.isAuthenticated, user.claims));
    }
    catch (response: any) {
        yield put(NotificationSaga.setNotification('Error', 'Cannot access authentication server.'));
        yield put(authenticationFailed());
    }
}