import { PropsWithChildren, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from '../../store';
import * as AuthenticationSaga from '../../store/sagas/authentication';

/**
 * This component will dispatch the authentication request, wait for a response, then render the children
 * @param props 
 */
export const AuthenticateUser = (props: PropsWithChildren<{}>): ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    useEffect(() => { dispatch(AuthenticationSaga.authenticationRequest()) }, []);

    const authentication = useSelector<GlobalState, AuthenticationSaga.AuthenticationState>(state => state.authenticationState);
    if (authentication.isAuthenticated !== undefined) {
        return props.children as ReactElement;
    }
    else {
        return null;
    }
};