import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';

/**
 * Declare all interfaces this saga uses
 * */

export interface FeatureFlag {
    applicationId: number;
    applicationName: string;
    featureFlagId?: number;
    name: string;
    description?: string;
    enabled: boolean;
    featureFlagHistory: FeatureFlagHistory[];
}

export interface FeatureFlagHistory {
    applicationId: number;
    featureFlagId?: number;
    name: string;
    description?: string;
    enabled: boolean;
    trackingState?: string;
    modifiedOn?: Date;
    modifiedBy?: string;
}

/**
 * Declare a main interface for this sagas state.
 * This should also be added to GlobalState in the ~/client/store/index.ts file
 * */

export interface FeatureFlagState {
    featureFlags: FeatureFlag[];
    loading: boolean;
};

export interface FeatureFlagHistoryState {
    featureFlagHistory: FeatureFlagHistory[];
    loading: boolean;
};