interface RoutePaths {
    home: string;
    applicationList: string;
    featureFlagList: string;
}

export const routePaths: RoutePaths = {
    home: '/',
    applicationList: '/Application/List',
    featureFlagList: '/FeatureFlag/List'
};

