import { Col, Container, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import * as NotificationSaga from '../../store/sagas/notification';
import Header from './Header';
import Menu from './Menu';

type Props = {
    children?: React.ReactNode
};

const Layout = ({ children } : Props) => {
    const notificationState = useSelector<GlobalState, NotificationSaga.NotificationState>((state) => state.notificationState);
    const dispatch = useDispatch();

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col id="sideMenu">
                        <Menu />
                    </Col>
                    <Col md="8" lg="9" xl="10" xs="12" id="mainContent">
                        {children}
                    </Col>
                </Row>
                <Row>
                    <footer>
                        <h6>ProAg is an equal opportunity provider. &copy; {new Date().getFullYear()} ProAg. All rights reserved.</h6>
                        <h5><a href="https://www.proag.com/privacy-non-discrimination-statements/" target="_blank">Privacy and Non-Discrimination Statements</a></h5>
                    </footer>
                </Row>
            </Container>

            {notificationState.notifications.length > 0 ?
                <ToastContainer className="p-3" position="top-end">
                    {notificationState.notifications.map(notification => (
                        <Toast bg="warning" key={notification.id} onClose={() => dispatch(NotificationSaga.clearNotification(notification.id))}>
                            <Toast.Header>
                                <strong className="me-auto">{notification.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{notification.body}</Toast.Body>
                        </Toast>
                    ))}
                </ToastContainer> : null
            }
        </>
    );
}

export default Layout;