import { Action, ActionCreator } from 'redux';
import { Claim } from '.';

const actionTypes = {
    AUTHENTICATION_REQUEST: '@@authentication/AUTHENTICATION_REQUEST',
    AUTHENTICATION_SUCCESS: '@@authentication/AUTHENTICATION_SUCCESS',
    AUTHENTICATION_FAILED: '@@authentication/AUTHENTICATION_FAILED'
};
export const ActionTypes = actionTypes;

export interface AuthenticationSuccessAction extends Action {
    isAuthenticated: boolean;
    claims: Claim[];
};

export type AuthenticationActions = AuthenticationSuccessAction | Action;

export const authenticationSuccess: ActionCreator<AuthenticationSuccessAction> = (isAuthenticated: boolean, claims: Claim[]) =>
({
    type: actionTypes.AUTHENTICATION_SUCCESS,
    isAuthenticated,
    claims
});

export const authenticationFailed: ActionCreator<Action> = () => ({
    type: actionTypes.AUTHENTICATION_FAILED
});