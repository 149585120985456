import { all, fork } from 'redux-saga/effects';
import * as ApplicationSaga from './application';
import * as AuthenticationSaga from './authentication';
import * as FeatureFlagsSaga from './featureFlag';
import * as NotificationSaga from './notification';

export const Reducers = {
    applicationState: ApplicationSaga.reducer,
    authenticationState: AuthenticationSaga.reducer,
    featureFlagState: FeatureFlagsSaga.reducer,
    notificationState: NotificationSaga.reducer
};

export const RootSaga = function* root() {
    const allSagas = [
        ...ApplicationSaga.Sagas,
        ...AuthenticationSaga.Sagas,
        ...FeatureFlagsSaga.Sagas,
        ...NotificationSaga.Sagas
    ];
    yield all(
        allSagas.map(saga => fork(saga))
    );
}