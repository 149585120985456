import { padEnd } from 'lodash';
import { Reducer } from 'redux';
import * as FeatureFlagSaga from './';
import { ActionTypes, FeatureFlagActions, GetFeatureFlagResponseAction, GetFeatureFlagHistoryResponseAction }
    from './actions';

/**
 * Define your reducer function.
 * This function accepts a current state and an action and should return the modified state.
 * This is a pure function and no logic or async code belongs here.
 *
 * This function should be added to the Reducers object in ~/client/store/sagas/index.ts
 * */

// The initial state will be kept in the store where all the latest updated values of the initial state will be. Think of the store as the single source of truth and we will be reading and updating values in the store that redux provides.
const initialState: FeatureFlagSaga.FeatureFlagState = { featureFlags: [], loading: false };

// Think of actions as anything that can happen in your app. 
const reducer: Reducer<FeatureFlagSaga.FeatureFlagState, FeatureFlagActions> =
    (state: FeatureFlagSaga.FeatureFlagState = initialState, action: FeatureFlagActions): FeatureFlagSaga.FeatureFlagState => {
        switch (action.type) {
            case ActionTypes.GET_FEATURE_FLAG_REQUEST:
                return { ...state, loading: true };

            case ActionTypes.GET_FEATURE_FLAG_SUCCESS:
                return { featureFlags: (action as GetFeatureFlagResponseAction).featureFlags, loading: false };


            case ActionTypes.GET_FEATURE_FLAG_HISTORY_REQUEST:
            case ActionTypes.POST_FEATURE_FLAG_REQUEST:
            case ActionTypes.PUT_FEATURE_FLAG_REQUEST:
            case ActionTypes.DELETE_FEATURE_FLAG_REQUEST:
                return { ...state, loading: true };

            case ActionTypes.GET_FEATURE_FLAG_HISTORY_SUCCESS:
                // action contains the API results
                var featureFlagHistories = (action as GetFeatureFlagHistoryResponseAction).featureFlagHistory;
                // legacy will not have history data; need to check for that
                // state contains all the feature flags
                // need to identify who the parent object is
                if (featureFlagHistories.length > 0) {
                    var featureFlagParent = state.featureFlags.find(e => e.featureFlagId === featureFlagHistories[0].featureFlagId);
                    // when a parent is identified, set the FeatureFlagHistory property
                    if (featureFlagParent) featureFlagParent.featureFlagHistory = featureFlagHistories;
                }
                return { ...state, loading: false };

            case ActionTypes.POST_FEATURE_FLAG_SUCCESS:
            case ActionTypes.PUT_FEATURE_FLAG_SUCCESS:
            case ActionTypes.DELETE_FEATURE_FLAG_SUCCESS:
            case ActionTypes.LOADING_COMPLETE:
                return { ...state, loading: false };

            case ActionTypes.CLEAR_STATE:
                return initialState;
        }

        return state;
    };

export default reducer;